export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST"
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS"
export const PROJECT_LIST_FAIL = "PROJECT_LIST_FAIL"

export const PROJECT_DETAILS_REQUEST = "PROJECT_DETAILS_REQUEST"
export const PROJECT_DETAILS_SUCCESS = "PROJECT_DETAILS_SUCCESS"
export const PROJECT_DETAILS_FAIL = "PROJECT_DETAILS_FAIL"

export const PROJECT_DELETE_REQUEST = "PROJECT_DELETE_REQUEST"
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS"
export const PROJECT_DELETE_FAIL = "PROJECT_DELETE_FAIL"

export const PROJECT_CREATE_REQUEST = "PROJECT_CREATE_REQUEST"
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS"
export const PROJECT_CREATE_FAIL = "PROJECT_CREATE_FAIL"
export const PROJECT_CREATE_RESET = "PROJECT_CREATE_RESET"

export const PROJECT_UPDATE_REQUEST = "PROJECT_UPDATE_REQUEST"
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS"
export const PROJECT_UPDATE_FAIL = "PROJECT_UPDATE_FAIL"
export const PROJECT_UPDATE_RESET = "PROJECT_UPDATE_RESET"
